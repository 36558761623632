.help {
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  .help-email {
    margin-top: 12px;
    .help-email-icon {
      font-size: 12px;
      margin-top: 2px;
      margin-right: 8px;
    }
    .help-email-link {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
