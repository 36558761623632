.start-notes {
  height: 100%;
  overflow: auto;
  &-header {
    padding: 30px 32px;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
    &-title {
      font-size: 20px;
      font-weight: bold;
      color: #19191a;
      line-height: 28px;
    }
  }

  &-card {
    &-text {
      color: #4a4a4d;
    }
    .ant-form-item-label {
      min-width: 90px;
      font-weight: bold;
      margin-right: 50px;
    }
    .ant-checkbox-wrapper,
    .ant-radio-wrapper {
      padding: 4px 0;
      white-space: nowrap;
    }
  }
}

@media (max-width: 575px) {
  .start-notes {
    padding-top: 56px;
    &-header {
      position: absolute;
      top: 48px;
      left: 0;
      right: 0;
      z-index: 8;
      padding: 12px 24px;
    }
  }
}
