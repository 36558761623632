@import 'flex-x-attribute';
@import 'layout-custom';

body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin-bottom: 0 !important;
}

// ant样式调整
.ant-btn + .ant-btn {
  margin-left: 8px;
}
.ant-card-actions > li {
  margin: 8px 0 !important;
}

// 列表标题
.common-title {
  font-size: 24px;
  font-weight: bold;
  color: #19191a;
  line-height: 28px;
  margin: 32px 24px;
}

// 筛选查询
.common-query {
  padding: 24px 32px 0 2px;
  .ant-row {
    width: 100%;
  }
  .ant-form-item {
    margin-bottom: 24px !important;
  }
  .ant-form-item-label {
    width: 100px;
  }
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

// 表格列表
.common-list {
  padding: 32px 32px 16px;
  .list-header {
    margin-bottom: 24px;
    .header-title {
      font-size: 20px;
      font-weight: bold;
      color: #19191a;
      line-height: 28px;
    }
    .header-operate {
      .refresh {
        margin: 0 24px;
        opacity: 0.45;
        cursor: pointer;
      }
    }
  }
}
// 弹窗表格列表
.common-model {
  margin: -8px 0;
  .model-header {
    margin-bottom: 16px;
    .header-title {
      font-weight: bold;
      color: #7b7b80;
      line-height: 24px;
    }
    .header-operate {
      .refresh {
        margin: 0 24px;
        opacity: 0.45;
        cursor: pointer;
      }
    }
  }
}
.list-table {
  .table-status {
    .circle {
      width: 6px;
      height: 6px;
      margin-right: 8px;
      border-radius: 50%;
      &.default {
        background: rgba(25, 25, 26, 0.2);
      }
      &.green {
        background: #12c99b;
      }
      &.blue {
        background: #0676ed;
      }
      &.red {
        background: #fa5d5d;
      }
      &.warning {
        background: #f2a600;
      }
    }
  }
  .table-select {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0 !important;
    }
  }
  .ant-typography {
    margin-bottom: 0 !important;
  }
  .ant-btn {
    padding: 0;
  }
  // 表格多级展示时子级间距调整
  .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: 4px 0 4px 54px !important;
  }
  // 隐藏表头各列之前的线
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  // 分页和表格之间间距调整
  .ant-table-pagination.ant-pagination {
    margin: 16px 0 0 0;
  }
}

// 表格选择项
.ant-select-dropdown {
  width: fit-content !important;
  .dropdown-title {
    font-weight: bold;
    color: #4a4a4d;
    line-height: 22px;
  }
  .dropdown-text {
    color: #acacb3;
    line-height: 22px;
  }
}

// 创建
.common-details {
  padding: 24px;
  .details-title {
    font-size: 20px;
    font-weight: bold;
    color: #19191a;
    line-height: 28px;
    margin-bottom: 34px;
  }
}

// 设置
.commom-setting {
  &-title {
    line-height: 50px;
    padding: 0 32px;
    border-bottom: 1px solid #e6e9f1;
    font-weight: bold;
  }

  &-content {
    margin: 24px 32px 16px;
  }
}

.ant-btn.common-button-dashed {
  border-style: dashed;
}

.common-buttons {
  margin: 24px 24px 0;
  padding: 0 32px;
  height: 56px;
  background-color: #fff;
}

// 详情
.common-detail-card:not(:last-child) {
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid #e6e9f1;
}
