// SiderCustom
.sider-custom {
  background: #fff !important;
  .ant-menu-inline {
    height: 100%;
    .ant-menu-item {
      font-size: 16px;
      line-height: 22px;
      padding: 0 15px !important;
    }
    .ant-menu-item:not(:last-child) {
      margin-bottom: 60px;
    }
    .ant-menu-item::after {
      margin: 8px 0;
    }
    .ant-menu-item a {
      color: #7b7b80;
      white-space: pre-wrap;
    }
    .ant-menu-item a:hover,
    .ant-menu-item-selected a {
      color: #0676ed;
    }
    .ant-menu-item-selected {
      background-color: #fff !important;
    }
  }
}

// HeaderCustom
.header-custom {
  height: 48px !important;
  background: #fff !important;
  box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
  padding: 0 16px !important;
  z-index: 9;
  .header-logo {
    height: 20px;
  }
  .header-btn {
    color: #7b7b80;
    padding: 0 0 0 8px !important;
  }
}

// BreadcrumbCustom
.breadcrumb-custom {
  width: 100%;
  padding: 16px 32px;
  background: #fff;
  .breadcrumb-item {
    font-size: 20px;
    font-weight: bold;
    color: #19191a;
    line-height: 28px;
    margin-top: 16px;
  }
}

// PageCustom
.page-custom {
  margin: 24px;
  background: #fff;
  border-radius: 2px;
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgba(228, 240, 252, 0.8);
  }
  .ant-menu-item-selected {
    color: #0676ed;
  }
}

@media (max-width: 575px) {
  .media-hidden {
    display: none !important;
  }
}
