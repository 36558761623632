.home {
  width: 100%;
  height: auto;
  background-color: #f0f2f5;
  .home-img {
    width: 100%;
    height: auto;
  }
  .home-beian {
    a {
      color: rgba(74, 74, 77, 0.45);
    }
    img {
      width: 24px;
      height: 24px;
      margin: 0 4px;
    }
  }
}

@media (max-width: 575px) {
  .home {
    min-width: 100%;
    .home-beian {
      flex-direction: column;
      font-size: 12px;
    }
  }
}
