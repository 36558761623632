.space-predict-edit {
  .tabs {
    .ant-tabs-nav {
      padding: 0 40px;
      background: #fff;
    }
  }
}

.space-predict-edit-extra {
  font-weight: 400;
  .picker {
    width: 100px;
    margin-right: 16px;
  }
  .select {
    width: 86px;
    margin-right: -16px;
  }
}

.space-predict-edit-template {
  padding: 16px 24px;
}

.space-predict-edit-table {
  margin-bottom: 24px;
  .ant-card-head .ant-card-head-title,
  .ant-table .ant-table-thead > tr > th,
  .ant-table .ant-table-summary {
    font-weight: 600;
  }

  .title-tips {
    color: rgba(0, 0, 0, 0.45);
    margin-left: 4px;
  }

  .rental-area {
    color: #19191a;
    margin: 8px 32px 24px;
    div:first-child {
      margin-right: 146px;
    }
  }

  .column-icon {
    width: 14px;
    height: 15px;
    margin: 0 -6px 4px 0;
  }
}

.space-predict-total {
  padding: 16px 24px;
  .num {
    margin-left: 74px;
    line-height: 22px;
    p:last-child {
      color: #4a4a4d;
    }
  }
}

.space-predict-main {
  height: calc(100vh - 288px);
  overflow-y: scroll;
  margin-top: 16px;
}

.column-configure {
  height: 40vh;
  overflow-y: scroll;
  .delete-button {
    margin: 0 8px;
    color: rgba(25, 25, 26, 0.6);
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .delete-button:hover {
    color: rgba(25, 25, 26, 0.8);
  }
  .delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .add-button {
    color: #0676ed;
    border: 1px dashed #e6e9f1;
  }
}
