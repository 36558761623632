.project-setting {
  padding: 16px 0;
}

.project-setting-detail {
  padding: 0 24px;
  .ant-form-item-control-input {
    min-height: unset;
  }
}

.project-setting-add {
  border: 1px dashed #e6e9f1;
  border-radius: 4px;
  height: 32px;
  color: #0676ed;
  margin: 0 24px;
  cursor: pointer;

  .anticon {
    margin-right: 5px;
  }
}

.project-setting-icon-wrap {
  margin-right: 8px;
  cursor: pointer;
}

.project-setting-level-2 {
  padding-left: 16px;
}

.project-setting-department {
  .ant-form-item {
    margin-bottom: 8px;
  }
}

.project-setting-form-checkbox {
  &.close {
    display: none;
  }

  .ant-col {
    margin-bottom: 4px;
  }
}
