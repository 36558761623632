.login {
  width: 100%;
  min-width: 380px;
  height: 100%;
  background: url('../../assets/images/login.png') no-repeat center;
  background-size: cover;
  background-color: #f0f2f5;
  position: relative;
  .login-logo {
    width: 162px;
    height: 38px;
    margin-bottom: 80px;
  }
  .login-form {
    width: 368px;
    .ant-input-affix-wrapper {
      height: 40px;
    }
    .login-form-icon {
      color: #0082ff;
    }
    .login-form-button {
      width: 100%;
      height: 40px;
      margin-top: 16px;
    }
    .login-form-forget {
      cursor: pointer;
      color: #acacb3;
      text-align: center;
      margin-top: 8px;
    }
  }
  .login-beian {
    position: absolute;
    bottom: 30px;
    a {
      color: rgba(74, 74, 77, 0.45);
    }
    img {
      width: 24px;
      height: 24px;
      margin: 0 4px;
    }
  }
}

.login {
  .ant-tabs {
    color: rgba(74, 74, 77, 0.65);
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0 0 24px 0 !important;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none !important;
  }
}

@media (max-width: 575px) {
  .login {
    min-width: 100%;
    min-height: 680px;
    .login-logo {
      margin-bottom: 60px;
    }
    .login-form {
      width: 100%;
    }
    .login-beian {
      flex-direction: column;
      font-size: 12px;
      bottom: 10px;
    }
  }
}
