.road-edit {
  padding: 16px 0;

  &-step {
    padding: 0 24px;
    min-width: 0;
  }

  .ant-upload-drag {
    background-color: #fff;
    &-icon {
      img {
        width: 64px;
        height: 64px;
        margin-bottom: 16px;
      }
    }
    &-text {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;
      margin-bottom: 4px;
    }
    &-hint {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
      line-height: 22px;
    }
  }

  .road-point-image {
    width: 100%;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
    }

    .road-point-item {
      position: absolute;
      min-width: 16px;
      height: 16px;
      line-height: 16px;
      transform: translate3d(-50%, -50%, 0);
      background: #0676ed;
      border-radius: 8px;
      font-size: 14px;
      color: #fff;
      text-align: center;
    }
  }
}

.road-point-preview {
  .ant-modal-header {
    display: none;
  }

  .ant-modal-body {
    padding: 20px;
  }

  .road-point-image {
    img {
      width: 100%;
    }
  }

  .ant-modal-close-x {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}
