.road-config {
  &-form {
    .ant-form-item-control-input {
      min-height: unset;
    }

    &-checkbox {
      .ant-form-item-control-input-content {
        padding: 2px 0;
      }
    }

    .ant-checkbox-wrapper {
      padding: 4px 0;
      white-space: nowrap;
    }
  }

  &-form-horizontal {
    .ant-row {
      flex-direction: row;
      .ant-form-item {
        margin: 0;
      }
      .ant-form-item-label {
        padding: 4px 0;
      }
    }
  }
}
