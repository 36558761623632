.space-predict-home {
  margin: 32px 0 32px 32px;
  .ant-row {
    width: 100%;
    height: auto;
    justify-content: flex-start;
    align-items: stretch;
    .ant-col {
      display: inherit;
      .ant-card {
        width: 100%;
        .ant-card-body {
          height: calc(100% - 50px);
          padding: 20px 24px 10px;
        }
      }
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      color: #19191a;
      line-height: 24px;
      margin-bottom: 8px;
    }
    .desc {
      font-size: 12px;
      color: #7b7b80;
      line-height: 20px;
    }

    .total {
      width: 100%;
      min-height: 280px;
      padding: 20px 24px;
      background-image: url('../../assets/images/space-total.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      box-shadow: 0 5px 10px -10px #14d0e4;
      border-radius: 2px;
      transition: box-shadow 0.3s, border-color 0.3s;
      cursor: pointer;
      &:hover {
        border-color: transparent;
        box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%),
          0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
      }
      .col1 {
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        line-height: 24px;
        margin-bottom: 10px;
      }
      .col2 {
        max-width: 185px;
        font-size: 12px;
        color: #fff;
        line-height: 20px;
        margin-bottom: 32px;
      }
      .col3 {
        .text {
          font-size: 14px;
          color: #51fbec;
          line-height: 20px;
          margin-right: 10px;
        }
        .img {
          width: 9px;
        }
      }
    }
  }
}
